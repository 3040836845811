import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DefaultTemplate from '@templates/DefaultTemplate';
import DownloadView from '@views/DownloadView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Button } from 'react-bulma-components';
import { DOWNLOAD_PAGE } from '../../constants';

const DownloadLinuxPage: FC<PageProps> = () => {
  const { t } = useTranslation(['download', 'pages']);

  const downloadUrl = `${DOWNLOAD_PAGE}/linux/latest`;

  return (
    <DefaultTemplate>
      <PageMeta
        description={t('pages:downloadPlatformDescription', { platform: 'Linux' })}
        title={t('pages:downloadPlatform', { platform: 'Linux' })}
      />
      <DownloadView
        DownloadButtons={[
          <>
            <Button
              color="primary"
              href={`${downloadUrl}?dist=ubuntu`}
              mb={4}
              renderAs="a"
              size="medium"
            >
              {t('downloadDistro', { distro: 'Ubuntu' })} ({t('64bit')})
            </Button>
            <a href={`${downloadUrl}?dist=ubuntu&arch=32`}>{t('32bitVersion')}</a>
          </>,
          <>
            <Button
              color="primary"
              href={`${downloadUrl}?dist=appimage`}
              mb={4}
              renderAs="a"
              size="medium"
            >
              {t('downloadDistro', { distro: 'AppImage' })} ({t('64bit')})
            </Button>
            <a href={`${downloadUrl}?dist=appimage&arch=32`}>{t('32bitVersion')}</a>
          </>,
        ]}
        platform="linux"
      />
    </DefaultTemplate>
  );
};

export default DownloadLinuxPage;
